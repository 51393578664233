import { SidebarItem } from "@/internal-models/sidebar-item";
import IconAssets from "@/constants/assets/icon-assets";
import { WebappUserRolesEnum } from "@/services/client/generated";

const getSidebarItems = (): SidebarItem[] => {
  return [
    {
      title: "terminalOperator",
      icon: IconAssets.GATE,
      activeIcon: IconAssets.GATE_ACTIVE,
      pageName: "/terminalOperator",
      roles: [
        WebappUserRolesEnum.TerminalOperationsAdmin,
        WebappUserRolesEnum.TerminalOperationsRead,
        WebappUserRolesEnum.TerminalOperationsWrite,
      ],
      services: [
        {
          icon: IconAssets.COMPASS_FILLED,
          activeIcon: IconAssets.COMPASS_OUTLINED,
          name: "dashboard",
          pagePath: "/terminalOperator",
        },

        /**
         *? This is a temporary solution to hide the booking data page for terminal operators
         *? The service will be accessible in the future when it will be available
         *? per company.
         * with @Fabian, 14-10-2024
         **/
        // {
        //   icon: IconAssets.ORDERS_FILLED,
        //   activeIcon: IconAssets.ORDERS_OUTLINED,
        //   name: "bookingOverview",
        //   pagePath: "/terminalOperator/bookingOverview",
        // },
        {
          icon: IconAssets.DISTURB_FILLED,
          activeIcon: IconAssets.DISTURB_OUTLINED,
          name: "acceptanceRestriction",
          pagePath: "/terminalOperator/deadlines",
        },
        {
          icon: IconAssets.BLOCK_FILLED,
          activeIcon: IconAssets.BLOCK_OUTLINED,
          name: "blockList.title",
          pagePath: "/terminalOperator/blockList",
        },
      ],
    },
    {
      title: "trainOperator",
      icon: IconAssets.TRAIN,
      activeIcon: IconAssets.TRAIN_ACTIVE,
      pageName: "/trainOperator",
      roles: [
        WebappUserRolesEnum.TrainOperatorRead,
        WebappUserRolesEnum.TrainOperatorWrite,
      ],
      services: [
        {
          icon: IconAssets.ORDERS_FILLED,
          activeIcon: IconAssets.ORDERS_OUTLINED,
          name: "bookingData",
          pagePath: "/trainOperator",
        },
      ],
    },
    {
      title: "forwarderOperator",
      icon: IconAssets.TRUCK,
      activeIcon: IconAssets.TRUCK_ACTIVE,
      pageName: "/forwarderOperator",
      roles: [
        WebappUserRolesEnum.ForwarderRead,
        WebappUserRolesEnum.ForwarderWrite,
      ],
      services: [
        {
          icon: IconAssets.COMPASS_FILLED,
          activeIcon: IconAssets.COMPASS_OUTLINED,
          name: "dashboard",
          pagePath: "/forwarderOperator",
        },
        {
          icon: IconAssets.TIME_FILLED,
          activeIcon: IconAssets.TIME_OUTLINED,
          name: "bookingRequest",
          pagePath: "/forwarderOperator/statusRequest",
        },
        {
          icon: IconAssets.USER_FILLED,
          activeIcon: IconAssets.USER_OUTLINED,
          name: "userManagement.title",
          pagePath: "/forwarderOperator/truckerManagement",
        },
        {
          icon: IconAssets.ORDERS_FILLED,
          activeIcon: IconAssets.ORDERS_OUTLINED,
          name: "bookingOverview",
          pagePath: "/forwarderOperator/bookingsOverview",
        },
      ],
    },
    {
      title: "userManagement",
      icon: IconAssets.USER_FILLED,
      activeIcon: IconAssets.USER_OUTLINED,
      pageName: "/userManagement",
      roles: [
        WebappUserRolesEnum.ConrooWrite,
        WebappUserRolesEnum.ConrooRead,
        WebappUserRolesEnum.ConrooAdmin,
      ],
    },
  ];
};

export default getSidebarItems;
