<template>
  <v-container>
    <v-form v-model="valid">
      <v-row justify="center">
        <v-col cols="12" sm="3">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ props }">
              <v-text-field
                class="rounded ma-0 pa-0"
                bg-color="white"
                readonly
                variant="outlined"
                :rules="[isRestrictionCorrect]"
                :placeholder="restrictionDatesPlaceHolder"
                :label="restrictionDatesPlaceHolder"
                :model-value="fakeDeadlineDate"
                v-bind="props"
                data-testid="restriction-inputSlot"
              />
            </template>
            <v-locale-provider :locale="i18n.locale.value">
              <v-date-picker
                v-model="restrictionDate"
                :title="$t('select_date')"
                :header="$t('select_start_date')"
                :no-data-text="$t('no_data_available')"
                color="green"
                no-title
                data-testid="restriction-datePicker"
                :min="restrictionDateFormatted"
              />
            </v-locale-provider>
          </v-menu>
        </v-col>
        <v-col>
          <HourDropdownPicker
            :label="$t('startingHourOfRestriction')"
            :hours="generateBaseHours()"
            @change-hour="putFirstHour"
            data-testid="restriction-startHourPicker"
          />
        </v-col>
        <v-col>
          <HourDropdownPicker
            :disabled="!startHour"
            :label="$t('endingHourOfRestriction')"
            :hours="validatedLastHours"
            :default-value="endHour ? `${endHour}:00` : null"
            @change-hour="putLastHour"
            data-testid="restriction-endHourPicker"
          />
        </v-col>
        <v-col>
          <TheButton
            @click.native="addOneToOneRestriction"
            :loading="false"
            :disabled="!valid"
            :title="$t('create')"
            :elevation="0"
            data-testid="create-restriction-button"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { format } from "date-fns";
import TheButton from "@/components/TheButton.vue";
import HourDropdownPicker from "@/components/HourDropdownPicker.vue";
import { useI18n } from "vue-i18n";
import { getViewModel } from "../acceptance-deadline/acceptance-restriction-logic";

const viewModel = getViewModel();
const i18n = useI18n();

const emit = defineEmits(["added-restriction"]);

const valid = ref(false);
const baseHours = ref<number[]>([]);
const validatedLastHours = ref<number[]>([]);
const startHour = ref<number | null>(null);
const endHour = ref<number | null>(null);
const today = new Date();
const restrictionDate = ref(new Date(today));

const addOneToOneRestriction = () => {
  if (!valid.value || startHour.value === null || endHour.value === null) {
    return;
  }
  emit("added-restriction", {
    restrictionDate: restrictionDate.value,
    startHour: startHour.value,
    endHour: endHour.value,
  });
};

const isRestrictionCorrect = (): string | boolean => {
  return viewModel.isOneToOneRestrictionCorrect(restrictionDate.value);
};

const generateBaseHours = (): number[] => {
  const startHour =
    restrictionDate.value.getDate() === today.getDate() ? today.getHours() : 0;
  return Array.from({ length: 24 - startHour }, (_, i) => i + startHour);
};

const putFirstHour = (value: string | null) => {
  if (!value) {
    startHour.value = null;
    endHour.value = null;
    validatedLastHours.value = [];
    valid.value = false;
    return;
  }

  const selectedHour = parseInt(value.split(":")[0], 10);
  if (!isNaN(selectedHour)) {
    startHour.value = selectedHour;
    validatedLastHours.value = baseHours.value.filter(
      hour => hour > selectedHour,
    );
    valid.value = false;
  }
};

const putLastHour = (value: string) => {
  if (value === null) {
    endHour.value = value;
    valid.value = false;
    return;
  }

  const selectedHour = parseInt(value.split(":")[0], 10);

  if (!isNaN(selectedHour)) {
    endHour.value = selectedHour;
    valid.value = true;
  } else {
  }
};

const fakeDeadlineDate = computed(() =>
  viewModel.getFakeDate(restrictionDate.value),
);

const restrictionDatesPlaceHolder = computed(
  () => i18n.t("oneToOneDate") as string,
);

const restrictionDateFormatted = computed(() => format(today, "yyyy-MM-dd"));

onMounted(() => {
  baseHours.value = generateBaseHours();
});
</script>
