<template>
  <v-container fluid>
    <v-col>
      <VirtualTable
        v-if="acceptanceDeadlines"
        :items="acceptanceDeadlines"
        :item-key="'id'"
        :headers="headers"
        :loading="loading"
        :header-section-name="$t('createAcceptance').toUpperCase()"
        :searchable="true"
        :search-text="$t('terminal_operator.search_by_train_number')"
        :custom-filter="filterByTrainNumber"
        @load-more="allLoaded"
      >
        <template #header>
          <AddAcceptanceDeadline @created-deadline="fetchAcceptanceDeadlines" />
        </template>
        <template #item.train_number="{ value }">
          {{ value.trains ? value.trains[0].trainNumber : "" }}
        </template>
        <template #item.departure_date="{ value }">
          {{ viewModel.parseDepartureDateForElement(value) }}
        </template>
        <template #item.acceptance_deadline_date="{ value }">
          {{ viewModel.parseAcceptanceDeadlineDateForElement(value) }}
        </template>
        <template #item.actions="{ value }">
          <v-btn icon variant="flat" slim @click="openDialog(value)">
            <v-icon icon="mdi-close-circle-outline" color="red"></v-icon>
          </v-btn>
        </template>
      </VirtualTable>
    </v-col>

    <ConfirmationDialog
      :dialog="deleteDialog"
      :question-text="$t('deleteConfirmationAcceptanceDeadline')"
      @confirm="deleteDeadline"
      @deny="denyDialog"
    />
  </v-container>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";
import AddAcceptanceDeadline from "@/views/terminal-operator/acceptance-deadline/AddAcceptanceDeadline.vue";
import { AcceptanceDeadline } from "@/services/client/generated/api";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { getViewModel } from "./acceptance-restriction-logic";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import { useI18n } from "vue-i18n";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";

const viewModel = getViewModel();
const terminalStore = useTerminalStore();
const forwarderStore = useForwarderStore();
const { t } = useI18n();

const deleteDialog = ref(false);
const acceptanceDeadlines = ref<AcceptanceDeadline[] | null>(null);
const selectedDeadline = ref<AcceptanceDeadline | null>(null);
const loading = ref(true);

const fetchAcceptanceDeadlines = async (): Promise<void> => {
  loading.value = true;
  try {
    acceptanceDeadlines.value = await viewModel.getAcceptanceDeadlines();
  } finally {
    loading.value = false;
  }
};

const deleteDeadline = async (): Promise<void> => {
  try {
    if (selectedDeadline.value) {
      await viewModel.deleteAcceptanceDeadline(selectedDeadline.value);
      deleteDialog.value = false;
      await fetchAcceptanceDeadlines();
      selectedDeadline.value = null;
    }
  } catch (e) {
    t(getErrorByTypeOrDefault(e));
  }
};

const openDialog = (item: AcceptanceDeadline): void => {
  deleteDialog.value = true;
  selectedDeadline.value = item;
};

const denyDialog = (): void => {
  deleteDialog.value = false;
  selectedDeadline.value = null;
};

const headers = computed(
  () =>
    [
      {
        title: t("trainNumber"),
        value: "train_number",
        key: "train_number",
        align: "center",
        sortRaw: (a: AcceptanceDeadline, b: AcceptanceDeadline) => {
          const trainNumberA = Number(a.trains?.[0]?.trainNumber);
          const trainNumberB = Number(b.trains?.[0]?.trainNumber);
          if (!isNaN(trainNumberA) && !isNaN(trainNumberB)) {
            return trainNumberA - trainNumberB;
          }

          return 0;
        },
      },
      {
        title: t("moveDate"),
        value: "departure_date",
        key: "departure_date",
        align: "center",
        sortRaw: (a: AcceptanceDeadline, b: AcceptanceDeadline) => {
          if (a.trains?.[0]?.trainDate && b.trains?.[0]?.trainDate) {
            const dateA = new Date(a.trains[0].trainDate).getTime();
            const dateB = new Date(b.trains[0].trainDate).getTime();

            if (!isNaN(dateA) && !isNaN(dateB)) {
              return dateA - dateB;
            }
          }

          return 0;
        },
      },
      {
        title: t("period"),
        value: "acceptance_deadline_date",
        key: "acceptance_deadline_date",
        align: "center",
        sortRaw: (a: AcceptanceDeadline, b: AcceptanceDeadline) => {
          const startA = new Date(a.deadlineStart).getTime();
          const startB = new Date(b.deadlineStart).getTime();

          if (!isNaN(startA) && !isNaN(startB)) {
            if (startA !== startB) {
              return startA - startB;
            }

            const endA = new Date(a.deadlineEnd).getTime();
            const endB = new Date(b.deadlineEnd).getTime();

            if (!isNaN(endA) && !isNaN(endB)) {
              return endA - endB;
            }
          }

          return 0;
        },
      },
      { title: "", value: "actions", align: "center" },
    ] as const,
);

const allLoaded = async (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  try {
    if (acceptanceDeadlines.value) {
      done("empty");
    } else {
      done("ok");
    }
  } catch (_) {
    done("error");
  }
};

const filterByTrainNumber = (
  _value: string,
  query: string,
  item?: { value: unknown; raw: AcceptanceDeadline },
): boolean => {
  if (!item?.raw.trains) return false;

  return item.raw.trains.some(
    train => train.trainNumber?.includes(query) ?? false,
  );
};

onMounted(async () => {
  await fetchAcceptanceDeadlines();
});

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      await fetchAcceptanceDeadlines();
    }
  },
);
</script>

<style lang="scss" scoped>
@import "../../../scss/list-transitions";
</style>
