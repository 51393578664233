<template>
  <div>
    <v-container fluid>
      <v-row fluid class="pl-5 pt-10" align="center" justify="space-between">
        <v-col cols="4">
          <TheMainHeader class="text-left" :text="$t('checkBookingData')" />
        </v-col>
        <v-col cols="2" class="pr-10" align="end">
          <TrainOperatorDropdown
            v-if="manyTrainOperators"
            @update:modelValue="switchTrainOperator"
            :train-operators="trainStore.trainOperatorsData"
          />
        </v-col>
        <v-col cols="12">
          <v-row class="pt-5" align="center" justify="center" no-gutters>
            <v-col cols="12">
              <v-tabs
                v-model="tab"
                align-tabs="center"
                grow
                bg-color="white"
                @update:modelValue="switchTab"
              >
                <v-tab class="text-black" href="#active">
                  <div class="tab-title">{{ $t("active") }}</div>
                </v-tab>
                <v-tab class="text-black" href="#completed">
                  <div class="tab-title">{{ $t("completed") }}</div>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="fill-height" fluid>
        <v-col cols="12" class="pa-5">
          <v-window v-model="tab">
            <v-window-item :key="1" value="active">
              <TrainOperatorBookingTileList
                :active="true"
                :loading="loading"
                :pages-loaded="page"
                :train-operator-bookings="trainOperatorBookings"
                @load-more="handleLoadMore"
              />
            </v-window-item>

            <v-window-item :key="2" value="completed">
              <TrainOperatorBookingTileList
                :active="false"
                :train-operator-bookings="trainOperatorBookings"
                :loading="loading"
                :pages-loaded="page"
                @selection-change="handleSelectionChange"
                @delete-selected-bookings="confirmBookings"
                @load-more="handleLoadMore"
              />
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import TrainOperatorBookingTileList from "./TrainOperatorBookingTileList.vue";
import TrainOperatorDropdown from "@/views/train-operator/bookings-page/TrainOperatorDropdown.vue";
import {
  TrainOperator,
  TrainOperatorBooking,
} from "@/services/client/generated";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { getViewModel } from "./train-operator-bookings-logic";
import DropdownItem from "@/internal-models/dropdown-item";
import { useTrainStore } from "@/store/useTrainStore";

const terminalStore = useTerminalStore();
const forwarderStore = useForwarderStore();
const trainStore = useTrainStore();
const viewModel = getViewModel();
const PAGE_SIZE = 50;

const tab = ref<number>(0);
const active = ref(true);
const page = ref(0);
const size = ref(PAGE_SIZE);
const loading = ref(false);
const switchingTabs = ref(false);

const trainOperatorBookings = ref<TrainOperatorBooking[]>([]);
const selectedBookings = ref<TrainOperatorBooking[]>([]);

const manyTrainOperators = computed(() => {
  return trainStore.trainOperatorsData.length > 0;
});

const getTrainOperators = async (): Promise<void> => {
  const operators = await viewModel.getTrainOperators();
  trainStore.setTrainOperators(operators);
  if (!trainStore.selectedTrainOperatorData) {
    trainStore.setSelectedTrainOperator(operators[0]);
  }
};

const getOperatorBookings = async (
  isActive: boolean,
): Promise<TrainOperatorBooking[]> => {
  if (!trainStore.selectedTrainOperator) {
    return [];
  }

  const loadedBookings = await viewModel.getBookingsForTrainOperator(
    trainStore.selectedTrainOperator.id,
    page.value,
    size.value,
    isActive,
  );

  if (loadedBookings.length > 0) {
    const newBookings = loadedBookings.filter(
      newBooking =>
        !trainOperatorBookings.value.some(
          existingBooking => existingBooking.bookingId === newBooking.bookingId,
        ),
    );

    trainOperatorBookings.value.push(...newBookings);
    page.value++;
  } else if (
    trainOperatorBookings.value.length === 0 &&
    loadedBookings.length === 0
  ) {
    trainOperatorBookings.value = loadedBookings;
  }

  return loadedBookings;
};

const confirmBookings = async () => {
  const selectedBookingIds = selectedBookings.value.map(
    booking => booking.bookingId,
  );

  if (!trainStore.selectedTrainOperator) {
    return;
  }

  await viewModel.confirmBookingForTrainOperator(
    selectedBookingIds,
    trainStore.selectedTrainOperator.id,
  );

  trainOperatorBookings.value = trainOperatorBookings.value.filter(
    booking => !selectedBookingIds.includes(booking.bookingId),
  );

  selectedBookings.value = [];
};

const handleSelectionChange = (selectedItems: TrainOperatorBooking[]) => {
  selectedBookings.value = selectedItems;
};

const handleLoadMore = async (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  if (loading.value || switchingTabs.value) return;

  loading.value = true;

  done("loading");

  try {
    if (trainStore.selectedTrainOperator) {
      const loadedBookings = await getOperatorBookings(active.value);

      if (loadedBookings.length === 0) {
        done("empty");
      } else {
        done("ok");
      }
    }
  } catch (_) {
    done("error");
  } finally {
    loading.value = false;
  }
};

const switchTab = async (item: unknown): Promise<void> => {
  switchingTabs.value = true;

  tab.value = item as number;
  active.value = tab.value === 0;
  page.value = 0;
  size.value = PAGE_SIZE;

  trainOperatorBookings.value = [];

  try {
    loading.value = true;
    await getOperatorBookings(active.value);
  } finally {
    loading.value = false;
    switchingTabs.value = false;
  }
};

const switchTrainOperator = async (item: DropdownItem): Promise<void> => {
  if (loading.value) return;

  trainStore.setSelectedTrainOperator(item.value as TrainOperator);
  trainOperatorBookings.value = [];
  page.value = 0;
  size.value = PAGE_SIZE;
  loading.value = true;

  try {
    await getOperatorBookings(active.value);
  } finally {
    loading.value = false;
  }
};

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      loading.value = true;
      page.value = 0;
      size.value = PAGE_SIZE;
      trainOperatorBookings.value = [];

      try {
        await getTrainOperators();
        await getOperatorBookings(active.value);
      } finally {
        loading.value = false;
      }
    }
  },
);

onMounted(async () => {
  loading.value = true;
  page.value = 0;
  size.value = PAGE_SIZE;

  try {
    await getTrainOperators();
    await getOperatorBookings(active.value);
  } finally {
    loading.value = false;
  }
});
</script>
