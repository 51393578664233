<template>
  <v-container class="pa-10" fluid>
    <TheMainHeader
      class="text-left pl-10 pt-10"
      :text="$t('truckerManagement')"
    />
    <div class="pa-10">
      <VirtualTable
        :items="truckers"
        :headers="headers"
        :pages-loaded="page"
        @load-more="handleLoadMore"
      >
        <template #item.registered="{ value }">
          {{ registeredTruckerDate(value) }}
        </template>

        <template #item.email="{ value }">
          <v-tooltip location="bottom">
            <template #activator="{ props: isActive }">
              <span v-bind="isActive" class="text-truncate-content">
                {{ value.email ?? i18n.t("missing") }}
              </span>
            </template>
            <span>{{ value.email ?? i18n.t("missing") }}</span>
          </v-tooltip>
        </template>

        <template #item.firstName="{ value }">
          <EditableField
            v-if="value.firstName && value.userId"
            color="white"
            :value="value.firstName"
            :placeholder="$t('name')"
            :rules="[validateMissingInput]"
            :readonly="isEditable(value.userId)"
            @input-change="v => (value.firstName = v)"
          />
        </template>

        <template #item.lastName="{ value }">
          <EditableField
            v-if="value.lastName && value.userId"
            color="white"
            :value="value.lastName"
            :placeholder="$t('surname')"
            :rules="[validateMissingInput]"
            :readonly="isEditable(value.userId)"
            @input-change="v => (value.lastName = v)"
          />
        </template>

        <template #item.licensePlate="{ value }">
          <EditableField
            v-if="value.licensePlate && value.userId"
            :value="value.licensePlate"
            :placeholder="$t('licensePlate')"
            class="pa-2"
            color="white"
            :rules="[validateMissingInput]"
            @input-change="v => (value.licensePlate = v)"
            :readonly="isEditable(value.userId)"
          />
        </template>

        <template #item.lastBookingCompletedDate="{ value }">
          {{ lastCompletedBookingDate(value) }}
        </template>

        <template #item.actions="{ value }">
          <v-row
            class="approval-tiles"
            v-if="!value?.forwarderApproval?.approved"
          >
            <v-col cols="6" align-self="center">
              <div
                @click="approveTrucker(value.userId ?? '')"
                class="text-green font-weight-bold cursor-pointer text--uppercase"
              >
                {{ $t("approve") }}
              </div>
            </v-col>
            <v-col
              cols="6"
              align-self="center"
              class="text-red font-weight-bold cursor-pointer text--uppercase"
              @click="denyTrucker(value.userId ?? '')"
            >
              {{ $t("deny") }}
            </v-col>
          </v-row>

          <v-row v-else-if="!isEditable(value.userId ?? '')" justify="center">
            <v-btn
              variant="text"
              icon="mdi-pencil"
              @click="editTrucker(value.userId ?? '')"
            />
            <v-btn
              variant="text"
              class="mx-4"
              icon="mdi-delete"
              color="red"
              @click="confirmDelete(value)"
            />
          </v-row>

          <v-row v-else justify="center">
            <v-btn
              v-if="value.userId"
              variant="text"
              color="green"
              icon="mdi-check"
              @click="updateTrucker(value)"
            />
            <v-btn
              v-if="value.userId"
              class="mx-4"
              variant="text"
              icon="mdi-close"
              @click="cancelEdit(value.userId)"
            />
          </v-row>
        </template>
      </VirtualTable>

      <ConfirmationDialog
        :dialog="deletionDialog"
        :question-text="$t('deleteTruckerConfirmation')"
        @confirm="deleteTrucker"
        @deny="deletionDialog = false"
      />
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import { TruckerForForwarderTO } from "@/services/client/generated";
import { useForwarderStore } from "@/store/useForwarderStore";
import { getViewModel } from "./forwarder-trucker-management-logic";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import { useI18n } from "vue-i18n";
import EditableField from "@/components/EditableField.vue";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";

const forwarderStore = useForwarderStore();
const viewModel = getViewModel();
const i18n = useI18n();

const truckers = ref<TruckerForForwarderTO[]>([]);
const selectedTrucker = ref<TruckerForForwarderTO | null>(null);
const originalValues = ref<Record<string, Partial<TruckerForForwarderTO>>>({});

const editable = ref<Record<string, boolean>>({});
const page = ref(0);
const deletionDialog = ref(false);
const loading = ref(false);

const headers = computed(
  () =>
    [
      {
        title: i18n.t("registered"),
        value: "registered",
        key: "registered",
        align: "center",
        sortRaw: (a: TruckerForForwarderTO, b: TruckerForForwarderTO) => {
          if (a.registeredDate && b.registeredDate) {
            const dateA = new Date(a.registeredDate).getTime();
            const dateB = new Date(b.registeredDate).getTime();

            if (!isNaN(dateA) && !isNaN(dateB)) {
              return dateA - dateB;
            }
          }

          return 0;
        },
      },
      {
        title: i18n.t("email"),
        value: "email",
        key: "email",
        align: "center",
      },
      {
        title: i18n.t("first_name"),
        value: "firstName",
        key: "firstName",
        align: "center",
        width: "13em",
        sortRaw: (a: TruckerForForwarderTO, b: TruckerForForwarderTO) => {
          const nameA = a.firstName?.toLowerCase();
          const nameB = b.firstName?.toLowerCase();

          if (nameA && nameB) return nameA.localeCompare(nameB);
        },
      },
      {
        title: i18n.t("last_name"),
        value: "lastName",
        key: "lastName",
        align: "center",
        width: "13em",
        sortRaw: (a: TruckerForForwarderTO, b: TruckerForForwarderTO) => {
          const nameA = a.lastName?.toLowerCase();
          const nameB = b.lastName?.toLowerCase();

          if (nameA && nameB) return nameA.localeCompare(nameB);
        },
      },
      {
        title: i18n.t("licensePlate"),
        value: "licensePlate",
        key: "licensePlate",
        align: "center",
        width: "13em",
      },
      {
        title: i18n.t("completedBookings"),
        value: "completedBookingsCount",
        key: "completedBookingsCount",
        align: "center",
      },
      {
        title: i18n.t("lastCompletedBooking"),
        value: "lastBookingCompletedDate",
        key: "lastBookingCompletedDate",
        align: "center",
      },
      { title: "Actions", value: "actions", align: "center", width: "18em" },
    ] as const,
);

const fetchTruckers = async (): Promise<TruckerForForwarderTO[]> => {
  const fetchedTruckers = await viewModel.getTruckersForForwarder(page.value);
  if (fetchedTruckers.length) {
    truckers.value.push(
      ...fetchedTruckers.filter(
        trucker => !truckers.value.some(e => e.userId === trucker.userId),
      ),
    );
    fetchedTruckers.forEach(trucker => {
      editable.value[trucker.userId ?? ""] = false;
    });
    page.value++;
    return fetchedTruckers;
  } else {
    loading.value = false;
    return [];
  }
};

const confirmDelete = (trucker: TruckerForForwarderTO) => {
  selectedTrucker.value = trucker;
  deletionDialog.value = true;
};

const handleLoadMore = async (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  if (loading.value) return;

  loading.value = true;
  done("loading");
  try {
    const loadedTruckers = await fetchTruckers();
    done(loadedTruckers.length ? "ok" : "empty");
  } catch {
    done("error");
  } finally {
    loading.value = false;
  }
};

const registeredTruckerDate = (trucker: TruckerForForwarderTO): string => {
  if (!trucker || !trucker.registeredDate) {
    return i18n.t("missing") as string;
  }
  return viewModel.parseIsoIntoDate(trucker.registeredDate, i18n.locale.value);
};

const lastCompletedBookingDate = (trucker: TruckerForForwarderTO): string => {
  if (!trucker || !trucker.lastBookingCompletedDate) {
    return i18n.t("missing") as string;
  }
  return viewModel.parseIsoIntoDateTime(
    trucker.lastBookingCompletedDate,
    i18n.locale.value,
  );
};
const validateMissingInput = (v: string): boolean | string =>
  v || (i18n.t("fieldRequired") as string);

const isEditable = (userId: string): boolean => editable.value[userId] ?? false;

const updateApproval = async (truckerId: string, approve: boolean) => {
  await viewModel.approveTrucker(truckerId, approve);
  const truckerIndex = truckers.value.findIndex(e => e.userId === truckerId);
  if (truckerIndex !== -1) {
    if (approve && truckers.value[truckerIndex].forwarderApproval)
      truckers.value[truckerIndex].forwarderApproval.approved = true;
    else truckers.value.splice(truckerIndex, 1);
  }
  delete editable.value[truckerId];
};

const approveTrucker = async (truckerId: string): Promise<void> =>
  await updateApproval(truckerId, true);

const denyTrucker = async (truckerId: string): Promise<void> =>
  await updateApproval(truckerId, false);

const updateTrucker = async (trucker: TruckerForForwarderTO): Promise<void> => {
  if (!editable.value[trucker.userId ?? ""]) return;
  await viewModel.updateTrucker(trucker.userId ?? "", {
    firstName: trucker.firstName,
    lastName: trucker.lastName,
    licensePlate: trucker.licensePlate,
  });
  editable.value[trucker.userId ?? ""] = false;
};

const deleteTrucker = async () => {
  if (!selectedTrucker.value?.userId) return;
  await viewModel.deleteTrucker(selectedTrucker.value.userId);
  truckers.value = truckers.value.filter(
    trucker => trucker.userId !== selectedTrucker.value?.userId,
  );
  delete editable.value[selectedTrucker.value.userId];
  selectedTrucker.value = null;
  deletionDialog.value = false;
};

const editTrucker = (userId: string): void => {
  const trucker = truckers.value.find(trucker => trucker.userId === userId);
  if (!trucker) return;

  originalValues.value[userId] = {
    firstName: trucker.firstName,
    lastName: trucker.lastName,
    licensePlate: trucker.licensePlate,
  };
  Object.keys(editable.value).forEach(id => (editable.value[id] = false));
  editable.value[userId] = true;
};

const cancelEdit = (userId: string): void => {
  const trucker = truckers.value.find(trucker => trucker.userId === userId);
  if (!trucker || !originalValues.value[userId]) return;

  Object.assign(trucker, originalValues.value[userId]);
  editable.value[userId] = false;
  delete originalValues.value[userId];
};

watch(
  () => forwarderStore.forwarder,
  async (newForwarder, oldForwarder) => {
    if (newForwarder?.id !== oldForwarder?.id) {
      truckers.value = [];
      page.value = 0;
      await fetchTruckers();
    }
  },
);
</script>

<style lang="scss" scoped>
@import "../../../scss/list-transitions";
</style>
