import {
  ispsAdminUserClient,
  ispsLicensesClient,
} from "@/services/client/configs/services";
import {
  IspsTruckerChangeTO,
  IspsTruckerChangeTODocumentStatusEnum,
  IspsTruckerChangeTOTerrorStatusEnum,
  IspsTruckerTO,
  TruckerIdentificationTODocumentStatusEnum,
  TruckerIdentificationTOTerrorStatusEnum,
} from "@/services/client/generated";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { useAuthStore } from "@/store/useAuthStore";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

export function useUserManagementLogic() {
  const authStore = useAuthStore();
  const page = ref(0);
  const size = ref(20);
  const loading = ref(false);
  const { t } = useI18n();

  const users = reactive<{ data: IspsTruckerTO[] }>({ data: [] });

  const admin = authStore.getUser()?.userData;
  const adminId = admin?.id;
  const snackbarStore = useSnackbarStore();

  let searchPayload: {
    userId: string | undefined;
    warningState: IspsTruckerChangeTODocumentStatusEnum | undefined;
    terrorState: IspsTruckerChangeTOTerrorStatusEnum | undefined;
  } = reactive({
    userId: undefined,
    warningState: undefined,
    terrorState: undefined,
  });

  const fetchTruckers = async (): Promise<IspsTruckerTO[]> => {
    if (adminId == null) {
      return [];
    }
    try {
      const currentPageUsers = await ispsAdminUserClient.getIspsTruckers(
        adminId,
        searchPayload.warningState,
        searchPayload.terrorState,
        searchPayload.userId,
        page.value,
        size.value,
      );

      if (currentPageUsers.data && currentPageUsers.data.length > 0) {
        users.data.push(...currentPageUsers.data);
        page.value++;
        return currentPageUsers.data;
      }
      return [];
    } catch (_) {
      users.data = [];
      return [];
    }
  };

  const filterUsers = async (payload: {
    userId: string | undefined;
    terrorState: TruckerIdentificationTOTerrorStatusEnum | undefined;
    warningState: TruckerIdentificationTODocumentStatusEnum | undefined;
  }) => {
    if (adminId == null) {
      return;
    }
    const { userId, terrorState, warningState } = payload;
    searchPayload = payload;

    page.value = 0;

    const searchedUsers = await ispsAdminUserClient.getIspsTruckers(
      adminId,
      warningState,
      terrorState,
      userId?.trim(),
      page.value,
      size.value,
    );

    page.value++;
    users.data = searchedUsers.data;
  };

  const addUserRevision = async (payload: {
    userId: string;
    data: IspsTruckerChangeTO;
  }) => {
    if (!adminId) {
      return;
    }
    await ispsAdminUserClient.ispsTruckerLog(
      adminId,
      payload.userId,
      payload.data,
    );

    filterUsers(searchPayload);
  };

  const resetIDNow = async (payload: string) => {
    if (adminId == null) {
      return;
    }
    try {
      await ispsAdminUserClient.deleteTruckerIdentifications(adminId, payload);
      snackbarStore.showSnackbar({
        text: t("userManagement.idnow_reset_success"),
        snackbarType: SnackbarType.SUCCESS,
      });
    } catch (e) {
      const errorText = t(
        getErrorByTypeOrDefault(e, "userManagement.error_resetting_idnow"),
      );
      snackbarStore.showSnackbar({
        text: errorText,
        snackbarType: SnackbarType.ERROR,
      });
      throw e;
    }
  };

  const approveUser = async (payload: {
    userId: string;
    entryId: number;
    approve: boolean;
  }) => {
    const { userId, entryId, approve } = payload;
    //TODO: ISSUE WITH 204 - 200 fix in backend
    try {
      if (adminId == null) {
        return;
      }
      await ispsAdminUserClient.patchIspsTrucker(adminId, userId, entryId, {
        approved: approve,
      });
    } catch (e) {
      const errorText = t(getErrorByTypeOrDefault(e));
      snackbarStore.showSnackbar({
        text: errorText,
        snackbarType: SnackbarType.ERROR,
      });

      throw e;
    }

    filterUsers(searchPayload);
  };

  const migrateLicense = async (payload: {
    userId: string;
    newUserId: string;
    licenseId: number;
  }) => {
    try {
      if (adminId == null) {
        return;
      }
      await ispsLicensesClient.migrateISPSLicenseToNewTrucker(
        adminId,
        payload.licenseId.toString(),
        {
          oldTruckerId: payload.userId,
          newTruckerId: payload.newUserId,
        },
      );
      snackbarStore.showSnackbar({
        text: "License migrated successfully.",
        snackbarType: SnackbarType.SUCCESS,
      });

      page.value = 0;
      await fetchTruckers();
    } catch (e) {
      const errorText = t(
        getErrorByTypeOrDefault(e, "userManagement.error_migrating_license"),
      );
      snackbarStore.showSnackbar({
        text: errorText,
        snackbarType: SnackbarType.ERROR,
      });
      throw e;
    }
  };

  const handleLoadMore = async (
    done: (status: "loading" | "error" | "empty" | "ok") => void,
  ) => {
    if (loading.value) return;

    loading.value = true;

    done("loading");

    try {
      const loadedTruckers = await fetchTruckers();

      if (loadedTruckers.length === 0) {
        done("empty");
      } else {
        done("ok");
      }
    } catch (_) {
      done("error");
    } finally {
      loading.value = false;
    }
  };

  return {
    authStore,
    adminId,
    users,
    loading,
    page,
    searchPayload,
    handleLoadMore,
    fetchTruckers,
    approveUser,
    filterUsers,
    addUserRevision,
    migrateLicense,
    resetIDNow,
  };
}
